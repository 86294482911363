import './App.css';

export default () => {
  sessionStorage.setItem("WhatsThis?", "WW91IGZvdW5kIG1lISBUaGUgZGVjeXB0aW9uIGtleSBwYTU1d2QlJQ==")

  return (
    <div className="app">
      <header className="app-header">My cv is here somewhere... ✌️</header>
    </div>
  );
}